<template>
    <div>
        <van-cell-group title="筛选">
            <van-search v-model="grid.sea.Visitor_Name" placeholder="请输入被访问人" @search="filter" @clear="filter" />
            <van-search v-model="grid.sea.SureName" placeholder="请输入确认人姓名搜索" @search="filter" @clear="filter" />

            <van-field readonly label="状态" placeholder="选择状态筛选" is-link @click="status.show = true"
                v-model="status.txt" />
            <van-popup v-model="status.show" round position="bottom">
                <van-picker :columns="status.lis" show-toolbar @cancel="status.show = false" @confirm="changeStatus"
                    value-key="name" />
            </van-popup>

            <van-cell title="日期" :value="date.val" @click="date.show = true" is-link />
            <van-calendar v-model="date.show" type="range" @confirm="dateSel" :show-confirm="false"
                :allow-same-day="true" :min-date="new Date('2021/7/1')" />
        </van-cell-group>
        <van-divider />
        <van-empty description="暂无数据" v-if="list.length == 0" />
        <!-- :immediate-check="false" 添加这个数据导致无法初始化 -->
        <van-list v-else :finished="grid.finish" @load="loadPage" v-model="grid.loading" style="padding: 10px 0px;">
            <div class="wcard" v-for="item in list" :key="item.ID" @click="edit(item.ID)">
                <div class="bd">
                    <van-row :gutter="8">
                        <van-col :span="24">
                            <p>姓名：{{ item.Visitor_Name }}</p>
                            <p style="font-size: 14px; color: #999">访问日期：{{ item.Visite_Date }}
                                <!--| datetime('YYYY-MM-DD')-->
                            </p>
                            <p style="font-size: 14px; color: #999">联系电话：{{ item.Visitor_Mobile }}</p>
                            <p style="font-size: 14px; color: #999">访问原因：{{ item.Reason }}</p>
                            <p style="font-size: 14px; color: #999">状态：{{ item.Is_Sure==0?"未确认":"已确认" }}</p>
                            <p style="font-size: 14px; color: #999" v-if="item.STA == 10">步骤：{{ item.STEP_DESC }}</p>
                        </van-col>
                    </van-row>
                </div>
                <!-- <div class="ft">
                    <van-row>
                        <van-col :span="8">提交人：{{ item.SUB_NAME }}</van-col>
                        <van-col :span="8">访问日期：{{ item.Visite_Date | datetime('YYYY-MM-DD') }}</van-col>
                    </van-row>
                </div> -->
            </div>
        </van-list>
    </div>
</template>

<script>
    import moment from "moment";
    export default {
        data() {
            return {
                list: [],

                sea: {},
                date: { show: false, val: '' },
                status: {
                    txt: '未确认',
                    show: false,
                    lis: [{ id: -1, name: "全部" }, { id: 0, name: "未确认" }, { id: 1, name: "已确认" }]
                },
                grid: {
                    sea: {
                        PAGE_SIZE: 10,
                        PAGE_INDEX: -1,
                        Is_Sure: 0
                    },
                    ls: [],
                    total: 0,
                    loading: false,
                    finish: false
                },
            }
        },
        mounted() {
            // this.filter()
        },
        methods: {
            filter() {
                this.getList()
            },
            loadPage() {
                console.log('123123')
                this.getList()
            },
            getList() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/Mobile/VIS/VisRegister/GetPageList",
                    data: self.grid.sea,
                    completed: function (its) {
                        console.log(its)
                        self.list = its.DATA.ITEMS;
                    }
                })
            },
            dateSel(d) {
                this.grid.sea.DTS = moment(d[0]).format('YYYY-MM-DD');
                this.grid.sea.DTE = moment(d[1]).format('YYYY-MM-DD')
                this.date.show = false;
                this.date.val = this.grid.sea.DTS + ' 至 ' + this.grid.sea.DTE;
                this.filter();
            },
            edit(id) {
                this.$router.push({ path: '/visitor/edit', query: { id: id } })
            },
            changeStatus(e) {
                console.log('eeee', e)
                this.grid.sea.Is_Sure = e.id
                this.status.txt = e.name
                this.status.show = false
                this.filter()
            },
        },
    }
</script>
<style lang="less" scoped>
    .wcard {
        margin: 0 12px 12px;
        overflow: hidden;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 4px rgba(0, 0, 0, .1)
    }

    .wcard .ft {
        border-top: 1px dashed #ebedf0;
        padding: 8px 16px;
        font-size: 12px;
        min-height: 20px
    }

    .wcard .ft .ov {
        color: #ee0a24;
        font-weight: bold
    }

    .wcard .bd {
        padding: 0px 15px
    }

    .wcard .bd .lf {
        text-align: center;
        color: #ee0a24;
        padding-top: 10px
    }

    .wcard .bd .lf h2 {
        font-size: 30px;
        font-weight: 500;
        display: inline;
    }

    .wcard .bd .lf span {
        font-size: 40%;
        font-weight: normal;
        margin-left: 2px
    }

    .wcard .bd .lf p {
        font-size: 14px;
        line-height: 16px
    }
</style>